<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="53" height="50" viewBox="0 0 53 50">
    <defs>
      <linearGradient id="q4r102xqia" x1="100%" x2="-13.166%" y1="97.426%" y2="-34.178%">
        <stop offset="0%" stop-color="#D20051" />
        <stop offset="100%" stop-color="#FFA300" />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g fill="url(#q4r102xqia)" fill-rule="nonzero" transform="translate(-494 -1528)">
        <g>
          <g>
            <path
              d="M5.018 31.968l-.306.331c-1.488 1.61-1.404 4.117.19 5.623l8.987 8.493c1.606 1.518 4.137 1.446 5.655-.16.077-.08.15-.166.22-.254L47.91 10.69c1.374-1.725 1.093-4.237-.628-5.615l-.337-.27c-1.725-1.381-4.242-1.102-5.623.622l-.007.01L16.208 36.99l-5.503-5.21c-1.604-1.52-4.136-1.45-5.654.154l-.033.034z"
              transform="translate(190 1389) translate(304.5 139)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
